<template>
  <div>
    <div class="personCertBox">
      <img src="@/assets/image/productCenter/personCertBanner.png" alt="个人证书">
      <h2>个人证书</h2>
      <p>个人数字证书是由豸信CA颁发的，用以标识个人在网络中的数字身份。数字证书以密码学为基础，采用数字签名、数字信封、时间戳服务等技术，在互联网上建立起有效的信任机制。用户使用此证书在互联网中标识证书持有人的数字身份，用来保证信息在互联网传输过程中的安全性和完整性。</p>
    </div>
    <v-functional :list="functional" />
    <v-featuresItem :list="features" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import featuresItem from './components/featuresItem.vue';
import functionalItem from './components/functionalItem.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'personCert1.png',
          title: '身份一致性',
          intro:
            '通过数字证书确认网络中传递信息的个人身份，确保个人线上身份同线下身份一致。'
        },
        {
          icon: 'orgCert-icon3.png',
          title: '合法性',
          intro:
            '利用数字证书进行数字签名，其作用与手写的签名具有同等法律效力。'
        },
        {
          icon: 'personCert2.png',
          title: '信息安全',
          intro:
            '数字证书可以帮助发送方使用接收方的公钥对数据加密，而接收方则使用自己的私钥解密，实现信息安全传输。'
        }
      ],
      features: [
        {
          title: '安全性',
          intro:
            '通过使用数字证书对信息加密，只有接收方才能阅读加密的信息，从而保证信息不会被他人窃取。'
        },
        {
          title: '完整性',
          intro:
            '利用数字证书可以校验传送的信息在传递的过程中是否被篡改过或丢失。'
        },
        {
          title: '便利性',
          intro:
            '用户根据实际需要，即时申请、开通并使用数字证书，用户不需要掌握加密技术或原理，就能够直接通过PKI/CA技术进行安全防护，便捷高效。'
        },
        {
          title: '实用性',
          intro:
            '个人数字证书已经广泛地应用到现实生活的各个领域：网上签约、网上订购、网上缴费、网上缴税等等。'
        }
      ]
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-featuresItem': featuresItem,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.personCertBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.98rem 0.53rem 0.68rem;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 5.32rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.79rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>